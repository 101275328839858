<template>

    <el-select
      v-model="scope.row.partSelects[partName]"
      :disabled="disabled"
      placeholder=""
      clearable
      size="mini"
      @visible-change="$event ? loadParts() : null"
      @change="selectChanged"
    >
      <el-option
        v-for="filteredPart in scope.row.partSelects['list_'+partName]"
        :key="filteredPart.id"
        :label="filteredPart[partName]"
        :value="filteredPart.id"
      ></el-option>
    </el-select>

</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import requestSender from "@/api/base/requestSender";
import ElementMediumFactPeriod from "@/components/tableColumnTemplates/card/mediumFacts/ElementMediumFactPeriod.vue";

export default {
  name: 'element-scale-part-single-table-column',
  components: {ElementMediumFactPeriod, ClickToEdit},
  mixins: [],

  props: {
    scope: {type: Object, required: true},
    value: {},
    disabled: {type: Boolean, default: false},
    initial: {type: Boolean, default: false},
    partName: {type: String, required: true},
  },
  computed: {},
  watch: {},
  beforeUpdate() {
    this.initStruct();
  },
  beforeMount() {
    this.initStruct();
  },
  mounted() {

  },
  data() {
    return {

    }
  },
  methods: {
    initStruct(){
      if( !this.scope.row.partSelects ){
        this.$set(this.scope.row, 'partSelects', {});
        this.$set(this.scope.row.partSelects, 'parts', []);
        this.$set(this.scope.row.partSelects, 'filteredParts', []);
        this.$set(this.scope.row.partSelects, 'part_1', null);
        this.$set(this.scope.row.partSelects, 'part_2', null);
        this.$set(this.scope.row.partSelects, 'part_3', null);

        if( this.scope.row.scale && this.scope.row.scale.current_scale_part ){
          this.valueLocal = this.scope.row.scale.current_scale_part.id;
          if( this.initial ){
            this.$set(this.scope.row.partSelects, 'filteredParts', [this.scope.row.scale.current_scale_part]);
            this.$set(this.scope.row.partSelects, 'part_1', this.scope.row.scale.current_scale_part.id);
            this.$set(this.scope.row.partSelects, 'part_2', this.scope.row.scale.current_scale_part.id);
            this.$set(this.scope.row.partSelects, 'part_3', this.scope.row.scale.current_scale_part.id);
          }

          this.preparePartLists();
        }
      }
    },

    loadParts() {
      if( !this.scope.row.partSelects.parts ){
        this.$set(this.scope.row.partSelects, 'parts', {});
      }
      if( this.scope.row.partSelects.parts.length ){
        return;
      }
      return requestSender('get', 'scale/get-parts-for-card-element', {
        id: this.scope.row?.id,
      })
        .then(data => {
          this.$set(this.scope.row.partSelects, 'parts', data.scale_parts);
          this.filterPartLists();
        })
    },

    async selectChanged(value){
      await this.loadParts();
      if( this.scope.row.partSelects.part_1 && this.scope.row.partSelects.part_2 && this.scope.row.partSelects.part_3 ){
        this.$emit('save-current_part_id', value);
      }
      this.filterPartLists();
    },

    filterPartLists(){
      if( !this.scope.row.partSelects ){
        console.log('wqrqwe');
      }
      this.$set(this.scope.row.partSelects, 'filteredParts', this.scope.row.partSelects.parts);

      if( this.scope.row.partSelects.part_1 ){
        let text = this.scope.row.partSelects.parts.find(p => p.id === this.scope.row.partSelects.part_1).part_1;
        this.$set(this.scope.row.partSelects, 'filteredParts', this.scope.row.partSelects.parts.filter(p => p.part_1 === text));
      }
      if( this.scope.row.partSelects.part_2 ){
        let text = this.scope.row.partSelects.filteredParts.find(p => p.id === this.scope.row.partSelects.part_2).part_2;
        this.$set(this.scope.row.partSelects, 'filteredParts', this.scope.row.partSelects.filteredParts.filter(p => p.part_2 === text));
      }
      if( this.scope.row.partSelects.part_3 ){
        let text = this.scope.row.partSelects.filteredParts.find(p => p.id === this.scope.row.partSelects.part_3).part_3;
        this.$set(this.scope.row.partSelects, 'filteredParts', this.scope.row.partSelects.filteredParts.filter(p => p.part_3 === text));
      }

      this.preparePartLists();
    },


    preparePartLists(){
      let list;

      list = this.scope.row.partSelects.filteredParts.map(({part_1}) => part_1);
      list = this.scope.row.partSelects.filteredParts.filter(({part_1}, index) => !list.includes(part_1, index + 1));
      this.$set(this.scope.row.partSelects, 'list_part_1', list);
      
      list = this.scope.row.partSelects.filteredParts.map(({part_2}) => part_2);
      list = this.scope.row.partSelects.filteredParts.filter(({part_2}, index) => !list.includes(part_2, index + 1));
      this.$set(this.scope.row.partSelects, 'list_part_2', list);
      
      list = this.scope.row.partSelects.filteredParts.map(({part_3}) => part_3);
      list = this.scope.row.partSelects.filteredParts.filter(({part_3}, index) => !list.includes(part_3, index + 1));
      this.$set(this.scope.row.partSelects, 'list_part_3', list);

    },
  }
}

</script>

